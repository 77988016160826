import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import moment from "moment"
import { Heading } from "insites-ui"
import Img from "gatsby-image"
import "../styles/blog-post.css"

const BlogPost = ({ data }) => {
  const {
    title,
    body,
    heroImage,
    publishDate,
    description,
  } = data.contentfulBlogPost

  const sizes = heroImage.sizes
  return (
    <Layout>
      <SEO
        title={title}
        description={description.description}
        image={heroImage.resize.src}
      />
      <section className={"blog-post-section"}>
        <div className={"container"}>
          <div className="row">
            <div className="col-xs-12">
              <article className="post-full post">
                <header className="post-full-header">
                  <Heading>{title}</Heading>
                  <div className="text-center meta">
                    {moment(publishDate).format("DD MMM YYYY")}
                  </div>
                </header>
                <figure className="post-full-image text-center">
                  <Img sizes={sizes} alt={heroImage.title} />
                </figure>
                <section
                  style={{ overflowY: "inherit", marginBottom: "2em" }}
                  className="post-full-content"
                  dangerouslySetInnerHTML={{
                    __html: body.childMarkdownRemark.html,
                  }}
                />
              </article>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
export default BlogPost
export const pageQuery = graphql`
  query($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      slug
      description {
        description
      }
      body {
        childMarkdownRemark {
          html
        }
      }
      heroImage {
        sizes(maxWidth: 1280) {
          ...GatsbyContentfulSizes
        }
        resize(width: 1000) {
          src
        }
        title
      }
      publishDate
    }
  }
`
